<template>
    <div class="prompted-symbol homePromptedSymbol">
        <div class="title">
            <token-icon :symbol="symbol" />
            <span>{{ symbol.metadata.baseSymbol || symbol.metadata.name }}</span>
        </div>
        <div class="price">
            <span class="span">${{ symbol.price }}</span>
        </div>  
        <div class="change">
            <div  class="percentage" :class="symbol.up ? 'up' : 'down'">{{ numShortener(symbol.price_change_pct.split('%')[0]) }}%</div>
        </div>       
    </div>
</template>

<script>
import TokenIcon from '../../Components/TokenIcon.vue';

export default {
    components: { TokenIcon },
    props: ['symbol'],

    methods: {
        numShortener: function (num) {
            let result;
            if (!isNaN(num)) {
                num = Number(num);
                result = Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + 'k' : Math.sign(num) * Math.abs(num).toFixed(2);
            } else {
                result = num;
            }
            return result;
        }
    }
};
</script>

<style scoped>
.prompted-symbol{
    display: flex;
    width: 100%;
    border-radius: 2px;
    padding: 20px;
    align-items: center;
    height: 100%;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
}
.prompted-symbol .token-icon{
    margin-right: 10px;
}
.prompted-symbol .price {
    margin-left: 15%;
    min-width: 130px;
    background: none;
}
.prompted-symbol .change {
    margin-left: 15%;
    min-width: 80px;
}
.title{
    display: flex;
    align-items: center;
    min-width: 105px;
}
.percentage{
    margin: auto;
    margin-left: 8px;
    display: inline-block;
    width: 76px;
    text-align: center;
    border-radius: 10px;
    font-size: 18px;
    padding: 5px 0;
}
.line-img{
    width: 81.25%;
    height: 40px;
}
.page-top .prompted-symbol .price{
    background: #fff;
    margin-top: 26px;
    padding-left: 5px;
}
.span{
    display: inline-block;
    height: 24px;
    color: #fff;
}
.up {
    color: #11CF8B;
    background-color: #DAFBEF;
    opacity: 1;
}

.up::before {
    content: url('@/assets/images/rising-arrow.png');
    display: inline-block;
    vertical-align: top;
    height: 0.7rem;
    margin-top: 2px;
}
.down {
    color: #FB3766;
    background-color: #FED7E0;
    opacity: 1;
}
.down::before {
    content: url('@/assets/images/falling-arrow.png');
    display: inline-block;
    vertical-align: sub;
    height: 0.7rem;
    margin-top: 2px;
}
@media (max-width: 768px) {
    .prompted-symbol .price {
        margin-left: 7%;
        min-width: 100px;
    }
    .prompted-symbol .change {
        margin-left: 4%;
    } 
    .prompted-symbol{
        font-size: 16px;
    }
    .title{
        display: flex;
        align-items: center;
        min-width: 105px;
    }
}
@media screen and ( min-width :1024px) and ( max-width :1240px) {
    .prompted-symbol .price {
        margin-left: 10%;
    }
    .prompted-symbol .change{
        margin-left: 5%;
    }
}
</style>